
            @import 'src/styles/mixins.scss';
        
html,
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    color: unset;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
.tspa-tooltip {
    display: inline-block;
    cursor: pointer;
    color: blue;
    border-bottom: 1px solid blue;
    position: relative;
    span {
        display: none;
        position: absolute;
        background-color: black;
        color: #fff;
        border-radius: 5px;
        padding: 10px;
        min-width: 150px;
        transform: translateX(-50%) translateY(-100%);
        left: 50%;
        top: -5px;
        line-height: 1.4;
        font-size: 9px;
    }
    &:hover {
        &:before,
        &:after,
        span {
            display: block;
        }
    }
    &:after {
        width: 9px;
        height: 9px;
        background-color: black;
        display: none;
        transform: translateX(-50%) translateY(-22px) rotate(45deg);
        left: 50%;
        content: "";
        position: absolute;
    }
}
.tcpa-Modal {
    display: inline-block;
    cursor: pointer;
    color: blue;
    border-bottom: 1px solid blue;
    > span {
        display: none;
    }
}
.withoutTS .successMsgWithOutTooltip {
    display: none !important;
}
.withTS .successMsgWithTooltip {
    display: none !important;
}
@layer components {
    .hide-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .horizontal-scroll {
        display: flex;
        overflow-x: scroll;
        @apply hide-scrollbar;
    }
    .horizontal-scroll-child {
        flex: 0 0 auto;
    }
    .absolute-v-center {
        top: 50%;
        transform: translateY(-50%);
    }
    .next-image {
        & > div {
            position: static !important;
            img {
                position: static !important;
                width: 100% !important;
                height: auto !important;
            }
        }
    }
}
.btnStyle {
    background-color: #4992e9;
    color: #fff !important;
    margin: 0 auto;
    display: block;
    width: fit-content;
    font-size: 20px;
    font-weight: 500;
    padding: 15px 46px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}
.btnStyle:hover {
    transition: all 0.3s ease-in-out;
    transform: translateY(-5px);
    text-decoration: none !important;
}

.offerListContainer {
    min-height: calc(100vh - 54px);
    padding: 0 10px;
    position: relative;
    background-color: #fff;

    .textBlock {
        h1 {
            font-size: 25px;
            font-weight: 700;
            margin: 0;
            line-height: 1.2;
            margin-bottom: 5px;
            @include sm {
                font-size: 40px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            margin: 0;
            color: #515151;
            @include sm {
                font-size: 25px;
            }
        }
    }
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }
    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton-text {
    width: 100%;
    height: 0.7rem;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-button {
    width: 100%;
    height: 50px;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-input {
    width: 100%;
    height: 30px;
    margin-bottom: 0.5rem;
    border-radius: 0.25rem;
}

.skeleton-text__body {
    width: 75%;
}

.skeleton-footer {
    width: 30%;
}

/******************************** severalbrands.com style ***********************************/

.postContainerSeveralBrandsDomain {
    margin-top: 30px;

    p {
        margin-top: 40px;
        font-size: 16px;
        line-height: 26px;
        color: #333;

        @include lg {
            font-size: 18px;
            line-height: 26px;
        }
        @include xxxl {
            font-size: 24px;
            line-height: 40px;
        }
    }
    a {
        color: #1693f0;
        &:hover {
            text-decoration: underline;
        }
    }
    ul {
        text-align: left;
    }
    img {
        margin-top: 24px;
    }
    ol {
        margin-top: 40px;
        margin-left: 25px;
        @include lg {
            margin-left: 35px;
        }
        list-style: decimal;
        li {
            font-size: 14px;
            line-height: 32px;

            @include lg {
                font-size: 18px;
            }
            @include xxxl {
                font-size: 24px;
                line-height: 40px;
            }
        }
    }
    li {
        ol,
        ul {
            margin-top: 5px !important;
            @include lg {
                margin-top: 10px !important;
            }
            margin-left: 15px !important;
        }
    }
    ul {
        // margin-top: 40px;
        margin-left: 30px;
        list-style: disc;
        @include lg {
            margin-left: 45px;
        }
        li {
            font-size: 14px;
            line-height: 32px;

            @include lg {
                font-size: 18px;
            }
            @include xxxl {
                font-size: 24px;
                line-height: 40px;
            }
            ul {
                margin-top: 10px;
                margin-left: 20px;
                list-style-type: circle;
                li {
                    font-size: 13px;
                    @include lg {
                        font-size: 16px;
                    }
                    @include xxxl {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    p + ul,
    p + ol {
        margin-top: 5px;
        @include lg {
            margin-top: 10px;
        }
    }

    h2 {
        font-size: 20px;
        line-height: 28px;
        margin-top: 40px;
        scroll-margin: 86px;
        padding: 5px 10px;
        position: relative;
        background-color: #f7f2fb;
        & > br {
            display: none;
        }
        span,
        strong,
        a {
            color: #1693f0;
        }

        @include lg {
            scroll-margin: 107px;
            font-size: 30px;
            line-height: 38px;
            padding: 12px 20px;
        }
        @include xxxl {
            scroll-margin: 115px;
            font-size: 50px;
            line-height: 70px;
        }
        a {
            color: black;
            :hover {
                text-decoration: none;
            }
        }
        :hover {
            text-decoration: none;
        }
    }
    h3 {
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
        margin-top: 40px;

        @include lg {
            font-size: 25px;
        }
        @include xxxl {
            font-size: 40px;
            line-height: 60px;
        }
        a {
            font-size: 15px;
            font-weight: 700;
            margin-top: 0;
            @include lg {
                font-size: 25px;
                color: #000;
            }
            @include xxxl {
                font-size: 40px;
                line-height: 60px;
            }
        }
    }
    h4 {
        font-size: 17px;
        font-weight: 600;
        line-height: 30px;
        margin-top: 30px;

        @include lg {
            font-size: 22px;
        }
        @include xxxl {
            font-size: 36px;
            line-height: 50px;
        }
    }
    h2 + p,
    h2 + ul {
        margin-top: 10px;
        @include lg {
            margin-top: 15px;
        }
    }
    h3 + p,
    h3 + ul,
    h4 + p,
    h4 + ul {
        margin-top: 5px;
        @include lg {
            margin-top: 10px;
        }
    }
    img + p {
        margin-top: -15px;
    }

    table {
        overflow-x: auto;
        width: 100% !important;
        max-width: 100% !important;
        min-width: 400px;
        border: 0 #c8d0df;
        border-collapse: collapse;
        margin-top: 20px;
    }
    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }
    tbody {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;
        max-width: 100%;
    }
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
    table tr th {
        color: #fff;
        border-color: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(176, 176, 176, 0.5);

        background: #072c60;
        padding: 15px;
        font-size: 0.875rem;
        display: table-cell;
        vertical-align: inherit;
        font-weight: bold;
        p {
            margin: 0;
            color: #fff;
        }
    }
    table tr td {
        padding: 15px;
        border: 1px solid rgba(176, 176, 176, 0.5);
        font-size: 0.875rem;
        display: table-cell;
        text-align: center;
        vertical-align: inherit;
        min-width: 200px;
        @include lg {
            min-width: auto;
        }
        p {
            margin: 0;
        }
    }
    .media.media--type-remote-video,
    .media.media--type-video {
        width: 100%;
    }
    .align-center {
        text-align: center;
    }
    .media {
        display: flex;

        align-items: flex-start;
    }
    .media--type-video-embed,
    .media--type-remote-video,
    .media--type-video {
        position: relative;
    }
    .align-center {
        margin-right: auto;
        margin-left: auto;
    }
    .embed-responsive {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
        padding: 0;
    }
    .embed-responsive .embed-responsive-item,
    .embed-responsive embed,
    .embed-responsive iframe,
    .embed-responsive object,
    .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    .litebox,
    .blazy iframe,
    .media iframe {
        display: block;
        max-width: 100%;
    }

    .embed-responsive-16by9::before {
        padding-top: 56.25%;
    }
    .embed-responsive::before {
        display: block;
        content: "";
    }
    .clearfix::after {
        display: block;
        clear: both;
        content: "";
    }
    .box.yellow-box {
        background: #fff9e5;
    }
    .box {
        position: relative;
    }
    .blue-box-bg,
    .box,
    .green-box-bg,
    .red-box-bg,
    .yellow-box-bg {
        margin-bottom: 1rem;
        padding: 25px;
    }
    .box.yellow-box:before {
        border-top: 30px solid #ef4f7f;
    }
    .box:before {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-right: 30px solid transparent;
    }
    .box:before,
    .image-hover:before {
        top: 0;
        left: 0;
        content: "";
    }
    .box.blue-box {
        background: #edf6ff;
    }
    .box {
        position: relative;
    }
    .blue-box-bg,
    .box,
    .green-box-bg,
    .red-box-bg,
    .yellow-box-bg {
        margin-bottom: 1rem;
        padding: 25px;
    }
    .box.blue-box:before {
        border-top: 30px solid #ef4f7f;
    }
    .box:before {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-right: 30px solid transparent;
    }
    .box:before,
    .image-hover:before {
        top: 0;
        left: 0;
        content: "";
    }
    .yellow-box-bg {
        background: #fff9e5;
    }
    .blue-box-bg,
    .box,
    .green-box-bg,
    .red-box-bg,
    .yellow-box-bg {
        margin-bottom: 1rem;
        padding: 25px;
        margin-top: 10px;
    }
    .btn {
        background-color: #ef4f80;
        font-weight: 700;
        color: white !important;
        border-radius: 8px;
        padding: 12px 28px;

        cursor: pointer;
        margin: 0 auto;
        width: fit-content;
        display: block;
        // min-width: 300px;
        text-align: center;
        :hover {
            text-decoration: none;
        }
        transition: all 0.3s ease-in-out;
        @include sm {
            font-size: 18px;
            padding: 12px 64px;
        }
        @include xxxl {
            font-size: 24px;
            line-height: 40px;
        }
    }
    .btn:hover {
        transition: all 0.3s ease-in-out;
        transform: translateY(-9px);
    }
    a.btn {
        text-decoration: none;
    }
    .table-responsive {
        max-width: calc(100vw - 2rem);
        width: auto;
        display: block;
        overflow-x: auto;
        // margin-top: 15px !important;
    }
    .iframe-responsive {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}
.grecaptcha-badge {
    display: none !important;
}

/******************************** severalbrands.com style ***********************************/

.backdrop {
    background-color: rgba(black, 0.8) !important;

    div > main > div::before {
        z-index: -1;
    }

    position: fixed;
    inset: 0;

    header {
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        pointer-events: none;
        cursor: none;
        filter: brightness(40%);
    }

    animation: fade-in 0.1s ease-in-out;
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.disable-scroll {
    height: 100% !important;
    overflow-y: hidden !important;
}

// torts blog styles
.tortsPostContainer {
    article.blog h2 {
        font-size: 1.5em !important;
        margin: 0.75em 0;
    }
    article.blog h3 {
        font-size: 1.17em !important;
        margin: 0.83em 0;
    }
    article.blog h4,
    article.blog p,
    article.blog blockquote,
    article.blog ul,
    article.blog fieldset,
    article.blog form,
    article.blog ol,
    article.blog dl,
    article.blog dir,
    article.blog menu {
        margin: 1.12em 0 !important;
    }
    article.blog h5 {
        font-size: 0.83em !important;
        margin: 1.5em 0 !important;
    }
    article.blog h6 {
        font-size: 0.75em !important;
        margin: 1.67em 0 !important;
    }
    article.blog h1,
    article.blog h2,
    article.blog h3,
    article.blog h4,
    article.blog h5,
    article.blog h6,
    article.blog b,
    article.blog strong {
        font-weight: bolder !important;
    }
    article.blog blockquote {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
    article.blog i,
    article.blog cite,
    article.blog em,
    article.blog var,
    article.blog address {
        font-style: italic !important;
    }

    .alignright {
        float: right;
    }
    article.blog ul {
        list-style: disc;
        padding-left: 25px;
    }
    article.blog ol {
        list-style: decimal;
        padding-left: 25px;
    }
    article.blog img {
        width: auto;
    }
    article.blog a {
        color: #0000ee;
        text-decoration: underline;
    }
}
// .offerContainer {
//     img {
//         margin-top: 24px;
//     }
//     ol {
//         margin-top: 40px;
//         margin-left: 25px;
//         @include lg {
//             margin-left: 35px;
//         }
//         list-style: decimal;
//         li {
//             font-size: 14px;
//             line-height: 32px;

//             @include lg {
//                 font-size: 18px;
//             }
//         }
//     }
//     li {
//         ol,
//         ul {
//             margin-top: 5px !important;
//             @include lg {
//                 margin-top: 10px !important;
//             }
//             margin-left: 15px !important;
//         }
//     }
//     ul {
//         // margin-top: 40px;
//         margin-left: 30px;
//         list-style: disc;
//         @include lg {
//             margin-left: 45px;
//         }
//         li {
//             font-size: 14px;
//             line-height: 32px;

//             @include lg {
//                 font-size: 18px;
//             }
//             strong {
//                 color: #777777;
//             }
//             b {
//                 color: #777777;
//             }
//             ul {
//                 margin-top: 10px;
//                 margin-left: 20px;
//                 list-style-type: circle;
//                 li {
//                     font-size: 13px;
//                     @include lg {
//                         font-size: 16px;
//                     }
//                 }
//             }
//         }
//     }
//     p + ul,
//     p + ol {
//         margin-top: 5px;
//         @include lg {
//             margin-top: 10px;
//         }
//     }

//     h2 {
//         font-size: 20px;
//         line-height: 28px;
//         margin-top: 40px;
//         color: #1693f0 !important;

//         padding: 5px 10px;
//         position: relative;
//         color: #1693f0;
//         background-color: #f2f8ff;
//         & > br {
//             display: none;
//         }
//         span,
//         strong,
//         a {
//             color: #1693f0;
//         }

//         @include lg {
//             font-size: 30px;
//             line-height: 38px;
//             padding: 12px 20px;
//         }
//         a {
//             color: black;
//             :hover {
//                 text-decoration: none;
//             }
//         }
//         :hover {
//             text-decoration: none;
//         }
//     }
//     h3 {
//         font-size: 15px;
//         font-weight: 700;
//         line-height: 30px;
//         margin-top: 40px;

//         @include lg {
//             font-size: 25px;
//         }
//         a {
//             font-size: 15px;
//             font-weight: 700;
//             margin-top: 0;
//             @include lg {
//                 font-size: 25px;
//                 color: #000;
//             }
//         }
//     }
//     h4 {
//         font-size: 17px;
//         font-weight: 600;
//         line-height: 30px;
//         margin-top: 30px;

//         @include lg {
//             font-size: 22px;
//         }
//     }
//     h2 + p,
//     h2 + ul {
//         margin-top: 10px;
//         @include lg {
//             margin-top: 15px;
//         }
//     }
//     h3 + p,
//     h3 + ul,
//     h4 + p,
//     h4 + ul {
//         margin-top: 5px;
//         @include lg {
//             margin-top: 10px;
//         }
//     }
//     img + p {
//         margin-top: -15px;
//     }

//     table {
//         overflow-x: auto;
//         width: 100% !important;
//         max-width: 100% !important;
//         min-width: 400px;
//         border: 0 #c8d0df;
//         border-collapse: collapse;
//         margin-top: 20px;
//     }
//     thead {
//         display: table-header-group;
//         vertical-align: middle;
//         border-color: inherit;
//     }
//     tbody {
//         display: table-row-group;
//         vertical-align: middle;
//         border-color: inherit;
//         max-width: 100%;
//     }
//     tr {
//         display: table-row;
//         vertical-align: inherit;
//         border-color: inherit;
//     }
//     table tr th {
//         color: #fff;
//         border-color: rgba(255, 255, 255, 0.5);
//         border: 1px solid rgba(176, 176, 176, 0.5);

//         background: #072c60;
//         padding: 15px;
//         font-size: 0.875rem;
//         display: table-cell;
//         vertical-align: inherit;
//         font-weight: bold;
//         p {
//             margin: 0;
//             color: #fff;
//         }
//     }
//     table tr td {
//         padding: 15px;
//         border: 1px solid rgba(176, 176, 176, 0.5);
//         font-size: 0.875rem;
//         display: table-cell;
//         text-align: center;
//         vertical-align: inherit;
//         min-width: 200px;
//         @include lg {
//             min-width: auto;
//         }
//         p {
//             margin: 0;
//         }
//     }

//     // div h2:only-child {
//     //     margin-bottom: -30px;
//     // }
// }

/********** EnduranceWarrantyProtect.com ********/
.dotsEnduranceWarrantyProtectStyle {
    :not(.slick-active) {
        button {
            &::before {
                color: #fff !important;
                font-size: 11px !important;
                opacity: 1 !important;
            }
            &:hover {
                &::before {
                    color: #ff870b !important;
                }
            }
        }
    }
    .slick-active {
        button {
            &::before {
                color: #ff870b !important;
                font-size: 11px !important;
                opacity: 1 !important;
            }
        }
    }
}
.removeWhiteBg {
    .offerListContainer {
        min-height: calc(100vh - 54px);
        padding: 0;
        position: static;
        background-color: transparent;
    }
}
/********** EnduranceWarrantyProtect.com ********/
